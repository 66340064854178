const getDefaultState = () => {
  return {
    news: [],
    selectedNewsItem: null
  };
};

const state = getDefaultState();

const actions = {
  fetchNews: ({ commit, dispatch, state }, dates) => {
    commit("setNews", []);

    api.getAllNews().then(res => {
      commit("setNews", res.data);
    });
  },

  setSelectedNewsItem: ({ commit, dispatch, state }, data) => {
    commit("setSelectedNewsItem", data);
  }
};

const getters = {
  news: state => state.news,
  selectedNewsItem: state => state.selectedNewsItem,
};

const mutations = {
  setNews(state, res) {
    state.news = res;
  },
  setSelectedNewsItem(state, res) {
    state.selectedNewsItem = res;
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
