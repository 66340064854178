import Vue from "vue";
import Vuex from "vuex";

import panel from "./modules/panel";
import stories from "./modules/stories";
import objects from "./modules/objects";
import events from "./modules/events";
import transport from "./modules/transport";
import places from "./modules/places";
import excursions from "./modules/excursions";
import news from "./modules/news";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state: {
    routes: [],
    currentRoutes: [],
    bus: {}
  },
  getters: {
    BUSES: state => {
      let arr = state.routes.reduce((total, item) => {
        return total.concat(Object.values(item.buses).map((value) => {
          return {
            lat: value.lat,
            lon: value.lon,
            extBusId: value.extBusId,
            routeNo: value.routeNo,
            number: value.number,
            routeId: value.routeId
          };
        }));
      }, []);
      return arr;
    },
    CURRENT_ROUTES: state => {
      return state.currentRoutes;
    },
    ROUTES: state => {
      return state.routes;
    }
  },
  mutations: {
    SET_ROUTE: (state, payload) => {
      function itemExists(input) {
        for (let i = 0, c = state.routes.length; i < c; i++) {
          if (state.routes[i].id === input) {
            return i;
          }
        }
        return false;
      }

      let index = itemExists(payload.id);

      if (index !== false) {
        state.routes.splice(index, 1, payload);
      } else {
        state.routes.push(payload);
      }
    },

    CLEAR_ROUTES: (state) => {
      state.routes = [];
    },

    SET_CURRENT_ROUTES: (state, payload) => {
      state.currentRoutes = Object.keys(payload.routes).map(i => payload.routes[i]);

      state.currentRoutes = state.currentRoutes.sort(function(a, b) {
        return (a.prediction === null) - (b.prediction === null) || +(a.prediction > b.prediction) || -(a.prediction < b.prediction);
      });
    },
  },
  actions: {},
  modules: {
    panel,
    stories,
    objects,
    events,
    transport,
    places,
    excursions,
    news,
  }
});
